/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @include mobile {
        margin-block-start: 0px;
    }

    &-Heading {
        font-size: 20px;
        font-weight: bold;
        color: var(--primary-blue-color);
        margin-block-end: 10px;
    }

    &-Description {
        color: #6c6f7a;
        font-size: 16px;
        line-height: 24px;
    }

    &-CustomerEmail {
        color: var(--primary-blue-color);
        font-weight: 500;
        font-size: 16px;
    }

    &-MainContent {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .CheckoutRegistrationLink {
        max-width: 470px;
        width: 100%;
        margin-block-start: 50px;

        a {
            width: 100%;
            margin-block-start: 25px;
        }

        .CheckoutSuccess-Description {
            margin-block-end: 0;
        }
    }

    &-ButtonWrapper {
        max-width: 470px;
        width: 100%;

        @include mobile {
            max-width: unset;
            inset-block-end: calc(var(--footer-total-height) + 20px);
        }

        a {
            width: 100%;
        }
    }
}
