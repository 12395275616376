/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutDeliveryOption {
    width: 100%;
    padding: 30px;
    flex-direction: column;

    &_isSelected {
        background-color: #f9fdff;
    }

    @include mobile {
        padding: 25px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid rgba(22, 126, 191, 0.1);
    }

    &:last-child {
        border-top: none;
    }

    &-Title {
        font-size: 16px;

        strong {
            color: var(--primary-blue-color);
            white-space: normal;
        }
    }

    &-Price {
        font-size: 16px;
        margin-block-start: 10px;

        strong {
            color: #6c6f7a;
            font-weight: 400;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &-DeliveryDateContainer {
        position: relative;
        width: 100%;
        margin: 30px 0 0;

        input {
            width: 100%;
        }
    }

    &-DeliveryDateTitle {
        font-size: 14px;
        font-weight: bold;
        color: var(--primary-blue-color);
    }

    &-Button {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    &-Row {
        display: flex;
        align-items: center;
        column-gap: 20px;

        svg {
            @include mobile {
                width: 32px;
                height: 32px;
            }
        }
    }

    .Field-Wrapper_type_radio {
        margin-top: -3px;
    }

    .enableExpress {
        pointer-events: initial;
    }

    .disableExpress {
        /*pointer-events: none;*/
        cursor: default;

        .CheckoutDeliveryOption {
            &-Price {
                strong {
                    color: var(--message-warning-color);
                }
            }
        }
    }

    &.enableExpress {
        opacity: 1;
    }

    &.disableExpress {
        opacity: 0.5;
    }

    @include mobile {
        &-Row {
            strong {
                white-space: pre-wrap;
            }
        }
    }
}
