/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.CheckoutPayment {
    padding: 0 !important;
    border: 1px solid rgba(22, 126, 191, 0.1);
    width: 100%;

    &-Button {
        padding: 30px;
    }

    &_isSelected {
        border: 1px solid rgba(22, 126, 191, 0.1);
        background-color: #f9fdff;
    }

    &:first-child {
        border-radius: 10px 10px 0 0;
        border-top: 1px solid rgba(22, 126, 191, 0.1);
    }

    &:last-child {
        border-radius: 0 0 10px 10px;
    }

    .Field {
        margin-block-start: 0;

        label {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary-blue-color);
        }
    }

    .apple-pay-button {
        width: 250px;
        height: 40px;
        -webkit-appearance: -apple-pay-button;
    }
}
