/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.Checkout {
    &-Title,
    &-Step {
        color: var(--primary-blue-color);
    }

    &-Heading {
        margin-block-start: 0px;
        font-size: 20px;
    }

    &-Wrapper {
        grid-template-columns: none;
    }
    
    &-InnerWrapper {
        &_multiColumn {
            .Checkout-Wrapper {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    &-StickyButtonWrapper {
        @include mobile {
            -webkit-border-before: 1px solid var(--primary-divider-color);
            border-top: 1px solid var(--primary-divider-color);
            position: fixed;
            width: 100%;
            z-index: 85;
            inset-block-end: var(--footer-total-height);
            background-color: var(--color-white);
            padding: 16px;
            margin-block-end: 12px;
        }
    }
}
