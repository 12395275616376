/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutPayments {
    margin-block-start: 24px;

    &-SubHeading {
        font-size: 16px;
        font-weight: 400;
        color: #6c6f7a;
        line-height: 24px;
        margin-block-end: 10px;
        text-transform: none;

        @include mobile {
            font-size: 14px;
        }
    }

    &-Methods {
        width: 100%;
    }

    &-Agreement {
        margin-block-start: 40px;
        color: #6c6f7a;

        @include mobile {
            margin-block-start: 20px;
        }

        a {
            color: inherit;
            font-size: inherit;
            text-decoration: underline;
        }
    }

    .apple-pay-button {
        width: 100%;
        height: 50px;
        -webkit-appearance: -apple-pay-button;
    }
}
