/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutAddressForm {
    .Field-Wrapper {
        grid-column-start: 1;
        grid-column-end: 3;

        &_name {
            &_city,
            &_region_string {
                display: none;
            }
        }

        .FieldSelect {
            #landmarks-placeholder {
                color: #666;
            }
        }
    }

    .FieldGroup {
        @include wide-desktop {
            grid-template-columns: none !important;
        }
    }

    .map-autocomplete {
        inset-block-start: 11px;
        text-align: center;

        input {
            box-sizing: border-box;
            border: 1px solid transparent;
            width: 60%;
            height: 40px;
            padding: 0px 45px 0px 12px;
            border-radius: 0px;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
            font-size: 14px;

            @include mobile {
                width: 98%;
            }
        }
    }

    .clearGoogleSearch {
        position: absolute;
        inset-inline-end: 140px;
        inset-block-start: 16px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
        background: #ececec;
        border-radius: 58%;
        font-weight: bold;
        font-size: 11px;

        @include mobile {
            inset-inline-end: 10px;
        }
    }

    .FieldForm-Fields {
        display: initial!important;
    }

    .gmnoprint {
        position: relative!important;
        inset-block-end: -400px!important;

        &.gm-bundled-control-on-bottom {
            float: inline-end;
            inset-inline-end: 0!important;
            inset-block-end: -300px!important;
        }
    }
}
