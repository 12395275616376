/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutAddressTable {
    .CheckoutAddressCard {
        text-align-last: left;
        box-sizing: border-box;
        min-height: 170px;
        width: 100%;
        padding: 30px;
        max-width: 525px;
        border: none;

        &-Heading {
            padding-block-end: 6px;
            text-align: start;
            display: flex;
            gap: 5px;

            &-UserName {
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                color: var(--primary-blue-color);
                margin-inline-end: 15px;
            }

            &-Status {
                height: 22px;
                background-color: #ddf7fe;
                color: #42444b;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                padding: 3px 14px;
                display: inline-block;
            }
        }

        &-Address {
            text-align: start;
            color: #6c6f7a;
            font-size: 16px;
            line-height: 24px;
        }

        &-Mobile {
            padding-block-start: 11px;

            &-Label {
                color: var(--primary-blue-color);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                padding-inline-end: 6px;
            }

            &-Value {
                color: #6c6f7a;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    &-Button {
        cursor: pointer;
        border-radius: 10px;
        border: 2px solid rgba(22, 126, 191, 0.1);

        &_isSelected {
            cursor: inherit;
            border: none;
            border: 2px solid #23336b;
        }
    }
}
