/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
.CheckoutDeliveryOptions {
    @include wide-desktop {
        width: 100%;
    }

    ul {
        border: 1px solid rgba(22, 126, 191, 0.1);
        border-radius: 10px;
    }
}
