/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutOrderSummary {
    @include desktop {
        border: none;
        border-top: 1px solid var(--secondary-base-color);
        margin-inline-start: 16px;
        padding-inline-start: 0;
    }

    &-OrderTotals {
        @include desktop {
            padding-block-start: 15px;
            border-bottom: 1px solid var(--secondary-base-color);
            margin-block-end: 15px;
        }
    }

    &-Header {
        h2 {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            text-transform: none;
        }

        @include mobile {
            display: block;
            margin-block-start: 20px;

            h2 {
                font-size: 16px;
            }

            .CheckoutOrderSummary-ItemsCount {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    &-CartItemList {
        display: grid;
        gap: 20px;
        background-color: #ffffff;
        padding: 20px 0;
    }

    &-SummaryItem {
        * {
            color: #6c6f7a;
            font-weight: 400;
            font-size: 16px;

            @include mobile {
                font-size: 14px;
            }
        }

        &_isTotal {
            @include desktop {
                border-block-start: none;
                border-bottom: 1px solid var(--secondary-base-color);
                margin-block-end: 15px;
            }

            p {
                font-weight: 400;
            }
        }

        &_isTotalField {
            border-block-start: 1px solid var(--secondary-base-color);
            padding: 22px 0;

            @include mobile {
                padding: 16px 0;
            }
        }
    }

    &-SummaryPriceLine {
        color: var(--secondary-blue-color);

        @include mobile {
            font-size: 14px;
            margin-inline-end: 10px;
        }
    }

    &-Price {
        strong {
            font-weight: 400;
        }
    }

    &-ItemsCount {
        font-size: 16px;
        color: #6c6f7a;
        font-weight: normal;
    }

    &-SummaryItemTitle {
        &_isTotalFieldTitle {
            font-size: 20px;
            color: var(--primary-blue-color);
            font-weight: bold;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    &-SummaryItemPrice {
        &_isTotalFieldPrice {
            font-size: 20px;
            color: var(--secondary-blue-color);
            font-weight: bold;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    &-ButtonWrapper {
        @include mobile {
            inset-block-end: calc(var(--footer-total-height) + 20px);
            height: unset;
            padding: 16px;
        }
    }

    .ExpandableContent {
        padding: 0;
    }

    .ExpandableContent-Heading {
        @include mobile {
            width: 100%;
        }
    }

    .ExpandableContent-Content {
        &_isContentExpanded {
            padding: 12px 0 0;
        }
    }
}
