/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* eslint-disable max-len */
/* eslint-disable max-lines */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutGuestForm {
    .Field-Wrapper_type_password {
        margin-block-end: 20px;
    }

    span {
        &.guest-msg {
            font-size: 14px;
        }
    }
}
